import React, { useEffect } from "react";
import HomeLayout from "./Layout";
import CardsPassport from "../../components/molecules/CardsPassport";
import OurBenefits from "../../components/Form/OurBenefits";
import StepsForm from "../../components/Form/Steps";
import Steps from "../../components/molecules/Steps";
import usePrepareHomeData from "./hooks/usePrepareHomeData";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { windowIsExist } from "../../components/utils";
import { amplitudeTrackEvent } from "../../components/utils/Amplitude";
import { useI18next } from "gatsby-plugin-react-i18next";
import "./styles.scss";

const HomeDefaultTemplate = ({ pageContext }) => {
  const { language } = useI18next();
  const { homeData, formData, stepsData, jumbotronTitle, stepsHowItWorks } =
    usePrepareHomeData(pageContext[`${language}`].data);

  const collapsedMobileTest = windowIsExist && window.innerWidth > 790 ? false : true;

  useEffect(() => {
    amplitudeTrackEvent("view_landing_home", {
      content: "landing-product",
    });
  }, []);

  return (
    <HomeLayout
      plainHeader={true}
      headerTitle={jumbotronTitle}
      collapsedMobileTest={collapsedMobileTest}>
      <Wrapper>
        <CardsPassport
          collapsedMobileTest={collapsedMobileTest}
          cards={formData.nodes}
          source="landing-home"
        />
        <OurBenefits data={homeData} />
        <StepsForm data={stepsData} />
      </Wrapper>
      <section
        id="how-it-works"
        className="section section--how">
        <Wrapper>
          <Steps
            title="How it works"
            stepsArray={stepsHowItWorks}
          />
        </Wrapper>
      </section>
    </HomeLayout>
  );
};

export default HomeDefaultTemplate;
